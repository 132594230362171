import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='公众号' {...props}>
    <Card variant='paper'>
      <Text variant='p' style={{ textAlign:'center'}}>
       欢迎关注前端达人

      </Text>
        <div style={{textAlign:'center'}}>
            <img
                style={{ width: "100px", height:"100px" }}
                alt="前端达人公众号"
                src='https://www.swiftui.cc/logo/qrcode.jpeg'
            />
        </div>

    </Card>
  </Section>
)

export default Commitment
